
import React from 'react'
import './sidebar.css'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux';

const SideBar = ({ idName }) => {

  const session = useSelector(state => state.session)

  // const togleMenu = () => {
  //   var x = document.getElementById("list-group-items");
  //   var y = document.getElementById("arrow");
  //   if (x.style.display === "block") {
  //     x.style.display = "none";
  //     y.style.transform = "rotate(0deg)";
  //   } else {
  //     x.style.display = "block";
  //     y.style.transform = "rotate(90deg)";
  //   }
  // }

  return (
    <div id={idName} className='sidebar'>

    
      <NavLink to='/app/dashboard' className='heading-link'>
        <i className="pi pi-chart-bar" id='h-icon'/> Dashboard
      </NavLink>

      <NavLink to='/app/contacts' className='heading-link'>
        <i className="pi pi-users" id='h-icon'/>Contacts
      </NavLink>

      <NavLink to='/app/campaigns' className='heading-link'>
        <i className="pi pi-megaphone" id='h-icon'/>Campaigns
      </NavLink>

      
      {session.user?.role?.name === 'Admin' && session.user?.company?.name === "Lipachat" ?
      <div>
         <NavLink to='/app/company' className='heading-link'>
          <i className="pi pi-building" id='h-icon'/>Company
        </NavLink>
        <NavLink to='/app/recon' className='heading-link'>
          <i className="pi pi-sync" id='h-icon'/>Recon
        </NavLink>
      </div>: ''}

      <NavLink to='/app/messages' className='heading-link'>
        <i className="pi pi-comment" id='h-icon'/>Messages
      </NavLink>

      <NavLink to='/app/billing' className='heading-link'>
        <i className="pi pi-credit-card" id='h-icon'/>Billing
      </NavLink>


      <NavLink to='/app/users' className='heading-link'>
        <i className="pi pi-users" id='h-icon' />Users
      </NavLink>

      <a href='/app/whatsapp/message' className='heading-link' style={{color: "green"}}>
        <i className="pi pi-qrcode" id='h-icon' style={{color: "green"}} />Scan QRCode
      </a>



    </div>
  )

}

export default SideBar