import React, { useState, useEffect } from 'react'
import '../../globalStyles/table.css'

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TabView, TabPanel } from 'primereact/tabview';
import Loading from '../../ReUsedComponents/loadingScreen/Loading';
import * as XLSX from 'xlsx'

import { getBillingStatement } from '../../../apis/BillingAPI';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dateFormat from 'dateformat';

const BillingHistory = () => {

  const dispatch = useDispatch()
  const action = useSelector((state) => state.action)
  const session = useSelector(state => state.session)

  const [searchQuery, setSearchQuery] = useState("")
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10)
  const [currentPage, setCurrentPage] = useState(1);

  const [statements, setStatements] = useState([])

  // Getting all statements
  useEffect(() => {
    getBillingStatement(dispatch, rows, currentPage, searchQuery).then(resp => {
      if (resp?.status === 200) { setStatements(resp?.data)}
      else { toast.error("Unable to load data!", { theme: "colored" }) }
    })
  }, [dispatch, rows, currentPage, searchQuery])

  const dateBodyTemplate = (rowData) => {
    return <span>{dateFormat(rowData.createdAt, "dd/mm/yyyy HH:MM", true)}</span>
  }

  const debitBodyTemplate = (rowData) => {
    return <span>{rowData.debitCreditFlag === 'DEBIT' ? rowData.amount : ''}</span>
  }
  const creditBodyTemplate = (rowData) => {
    return <span>{rowData.debitCreditFlag === 'CREDIT' ? rowData.amount : ''}</span>
  }

  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer], {
          type: EXCEL_TYPE
        });

        module.default.saveAs(data, fileName + '_' + new Date().getTime() + EXCEL_EXTENSION);
      }
    });
  };

  const exportExcel = () => {
    let exportData = statements?.data?.map(item => (
      item.debitCreditFlag === 'CREDIT' ? {
        'Transaction Type': item.narration, 'CR': item.amount,
        'DR': '', 'Balance': item.balance,
        'Date Created': dateFormat(item.createdAt, "dd/mm/yyyy HH:MM", true)
      } :

        {
          'Transaction Type': item.narration, 'CR': '',
          'DR': item.amount, 'Balance': item.balance,
          'Date Created': dateFormat(item.createdAt, "dd/mm/yyyy HH:MM", true)
        }
    )
    )
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAsExcelFile(excelBuffer, 'billings');
  };


  const paginatorLeft = <p style={{ color: 'black', fontWeight: '600' }}>Total Records: {statements.totalElements}</p>;
  const paginationTemplate = {
    layout: 'RowsPerPageDropdown CurrentPageReport',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [10, 20, 50, 100]

      return (
        <div>
          <label className='form-label'>Items per page: </label>
          <select type='number' className='rows-select-field' value={rows} onChange={(e) => setRows(e.target.value)}>
            {dropdownOptions.map((select, id) =>
              <option id='option' key={id} style={{ fontSize: '14px' }} type='number' value={select}>{select}</option>
            )}
          </select>
        </div>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <div style={{ display: 'flex', width: '180px', alignItems: 'center', textAlign: 'center' }}>

          <span style={{ width: '100px' }}>Page {currentPage} of {statements.totalPages}</span>

          <span>
            {currentPage === 1 ? '' :
              <i onClick={() => setCurrentPage(currentPage - 1)} className="pi pi-angle-left"
                style={{ width: '40px', cursor: 'pointer', 'fontSize': '1.1em', 'display': 'flex', 'textAlign': 'center' }} />}

            {currentPage === statements.totalPages || statements.totalPages === 0 ? '' :
              <i onClick={() => setCurrentPage(currentPage + 1)} className="pi pi-angle-right"
                style={{ width: '40px', cursor: 'pointer', 'fontSize': '1.1em', 'display': 'flex', 'textAlign': 'center' }} />}
          </span>
        </div>
      )
    },
  };


  return (

    <div className='table-list-page'>
      <Helmet> <title>Billing History | Lipachat</title> </Helmet>

      <div className='page-header-section'>
        <p className='page-title'>Billing List<p className='sub-title'>Manage Your Billing</p></p>
        {session.user?.role.name === 'Read' ? '' :
          <button onClick={() => { exportExcel() }} className='export-btn'><i id="btn-icon" className="pi pi-file-excel" /> Export Billings</button>
        }
      </div>

      <div className='data-table'>
        <div className='table-toolbar'>
          <div></div>

          <div className='search-table-input'>
            <span id='search-icon' class="material-symbols-outlined">search</span>
            <input type='text' className="s-table-input" placeholder='Search this table' onChange={(e) => setSearchQuery(e.target.value)} />
          </div>

        </div>


        <TabView className="tabview-header-icon">
          <TabPanel header="Campaign Billing" leftIcon="pi pi-megaphone">
            {action.pending || statements.length === 0 ? <Loading /> :
              <DataTable value={statements.data} removableSort responsive="true" rows={rows} responsiveLayout="stack" breakpoint="1200px"
                paginator paginatorLeft={paginatorLeft} paginatorTemplate={paginationTemplate} first={first} onPage={(event) => { setFirst(event.first); setRows(event.rows) }}
                paginatorClassName="justify-content-end" scrollable scrollHeight="480px" resizableColumns columnResizeMode="fit">
                <Column field="narration" header="Transaction Type" />
                <Column body={creditBodyTemplate} header="CR"/>
                <Column body={debitBodyTemplate} header="DR"/>
                <Column field="balance" header="Balance" sortable />
                <Column body={dateBodyTemplate} header="Created At"/>
              </DataTable>}
          </TabPanel>


          <TabPanel header="API Billing" leftIcon=" pi pi-whatsapp">
            {action.pending || statements.length === 0 ? <Loading /> :
              <DataTable value={statements.data} removableSort responsive="true" rows={rows} responsiveLayout="stack" breakpoint="1200px"
                paginator paginatorLeft={paginatorLeft} paginatorTemplate={paginationTemplate} first={first} onPage={(event) => { setFirst(event.first); setRows(event.rows) }}
                paginatorClassName="justify-content-end" scrollable scrollHeight="480px" resizableColumns columnResizeMode="fit">
                <Column field="narration" header="Transaction Type" sortable />
                <Column body={creditBodyTemplate} header="CR" />
                <Column body={debitBodyTemplate} header="DR" sortable />
                <Column field="balance" header="Balance" sortable />
                <Column body={dateBodyTemplate} header="Created At" sortable />
              </DataTable>}
          </TabPanel>

        </TabView>

      </div>


      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

    </div>

  );
}

export default BillingHistory