import axios from "axios";
import { actionStart, actionSuccess, actionFailed } from "../redux/ActionSlice";


export const sendResetPasswordEmail = async (dispatch, user) => {
    dispatch(actionStart())
    try {
        const resp = await axios.post(process.env.REACT_APP_BASE_URL + '/password', user, {
            headers: {
                'accept': 'application/json',
                'content-type': 'application/json',
            }
        })
        dispatch(actionSuccess())
        return resp
    } catch (err) {
        dispatch(actionFailed())
        return err
    }
}

export const getUserDetails = async (dispatch, token) => {
    dispatch(actionStart())
    try {
        const resp = await axios.get(process.env.REACT_APP_BASE_URL + '/password/'+token, {
            headers: {
                'accept': 'application/json',
                'content-type': 'application/json',
            }
        })
        dispatch(actionSuccess())
        return resp
    } catch (err) {
        dispatch(actionFailed())
        return err
    }
}


export const resetUserPassword = async (dispatch, user) => {
    dispatch(actionStart())
    try {
        const resp = await axios.put(process.env.REACT_APP_BASE_URL + '/password/reset', user, {
            headers: {
                'accept': 'application/json',
                'content-type': 'application/json',
            }
        })
        dispatch(actionSuccess())
        return resp
    } catch (err) {
        dispatch(actionFailed())
        return err
    }
}
