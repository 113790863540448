import axios from "axios";
import { actionStart, actionSuccess, actionFailed } from "../redux/ActionSlice";


export const getActiveSessions = async (dispatch) => {
    dispatch(actionStart())
    try {
        const response = await axios.get(process.env.REACT_APP_BASE_URL + `/session/all/count`,{
            headers: {
                'accept': 'application/json',
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
        dispatch(actionSuccess())
        return response
    } catch (err) {
        dispatch(actionFailed())
        return err
    }

}

export const startSession = async (companyNumber) => {
    try {
        const response = await axios.post(process.env.REACT_APP_BASE_URL + `/session/start`, {name: companyNumber},{
            headers: {
                'accept': 'application/json',
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
        return response
    } catch (err) {
        return err
    }

}


export const getWhatsppQRCode = async (dispatch) => {
    dispatch(actionStart())
    try {
        const resp = await axios.get(process.env.REACT_APP_BASE_URL + '/session/screenshot',{
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            responseType: 'blob'
        })
        dispatch(actionSuccess())
        return resp
    } catch (err) {
        dispatch(actionFailed())
        return err
    }
}



export const stopSession = async (companyNumber) => {
    try {
        const response = await axios.post(process.env.REACT_APP_BASE_URL + `/session/stop`, {logout: true, name: companyNumber},{
            headers: {
                'accept': 'application/json',
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
        console.log(response)
        return response
    } catch (err) {
        console.log(err)
        return err
    }

}


export const getAllSessions = async () => {
    try {
        const response = await axios.get(process.env.REACT_APP_BASE_URL+'/session/all',{
            headers: {
                'accept': 'application/json',
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }
        })
        return response
    } catch (err) {
        return err
    }

}

