
import React, { useEffect, useState } from 'react'
import './appmessages.css'
import { useDispatch, useSelector } from 'react-redux'

import { setQRCodeUrl, showScreenShot } from '../../redux/WhatsAppSlice';
import { ToastContainer, toast } from 'react-toastify';
import { startSession, getWhatsppQRCode, stopSession, getAllSessions } from '../../apis/WhatsappAPI';
const WhatsaapMessages = () => {

  const dispatch = useDispatch()
  const userInfo = localStorage.getItem("userinfo")
  const jsonData = JSON.parse(userInfo)
  const whatsAppSelector = useSelector((state) => state.whatsapp)
  const [sessionStatus, setSessionStatus] = useState(false)
  const [stopSessionAction, setStopSessionAction] = useState(false)
  const [sessionExists, setSessionExists] = useState(true)
  const [starting, setStarting] = useState(false)
  const [checkingSession, setCheckingSession] = useState(false)
  const [loggingOff, setLoggingOff] = useState(false)

  const [qrCodeStatus, setQrCodeStatus] = useState("")
  const [qrCodeGenerated, setQrCodeGenerated] = useState(false)
  const [gettingScreenshot, setGettingScreenshot] = useState(false)
  const [restartAction, setRestartAction] = useState(false)

  useEffect(() => {
    getSession();
  }, [dispatch])


  // Handle: Starting session if it exists
  const getSession = () => {
    console.log("Checking for existing session...")
    setCheckingSession(true)
    getAllSessions().then(resp => {
      const sessionExists = checkObjectExists(resp.data);
      if (!sessionExists) {
        console.log("Session does not exits.Start a new session...")
        setSessionExists(false)
      } else {
        resp.data.filter((item) => item.name === jsonData.company.phoneNumber).forEach((sess) => {
          if (sess.status === "SCAN_QR_CODE") {
            getQRCode()
            console.log("Generating QRCode: " + sess.status)
          } else if (sess.status === "WORKING") {
            getScreenshot()
            console.log("Generating Screenshot: " + sess.status)
          } else if (sess.status === "STOPPED") {
            startWhatsappSession()
            console.log("Starting Session status: " + sess.status)
          }else if (sess.status === "FAILED") {
          startWhatsappSession()
          console.log("Starting Session status: " + sess.status)
        }
          else {
            checkSessionStatus()
            setStarting(true)
            console.log("Session status is still: " + sess.status)
          }
        }
        )
      }
      setCheckingSession(false)
    })
  }


  // Handle: Generating whatsapp QRCode
  const getQRCode = () => {
    setStarting(true)
    getWhatsppQRCode(dispatch)
      .then((response) => {
        if (response?.status === 200) {
          const reader = new FileReader();
          reader.onloadend = () => {
            // sessionStorage.setItem("apiMessageForm", false)
            // dispatch(showApiMessageForm(sessionStorage.getItem("apiMessageForm")))

            sessionStorage.setItem("qrCodeUrl", reader.result)
            dispatch(setQRCodeUrl(reader.result))

            sessionStorage.setItem("screenshot", true)
            dispatch(showScreenShot(sessionStorage.getItem("screenshot")))
          };
          reader.readAsDataURL(response?.data)
          setQrCodeStatus("scanQr")
          setQrCodeGenerated(true)
          setStarting(false)
          setSessionStatus(true)
        } else if (response?.response?.status === 404) {
          setGettingScreenshot(false)
          setRestartAction(true)
        } else {
          toast.error("unable to generate QRCode!",{ theme: "colored" })
          console.log(response?.response?.status)
          setStarting(false)
        }

      });
  }

  const getScreenshot = () => {
    setGettingScreenshot(true)
    getWhatsppQRCode(dispatch)
      .then((response) => {
        if (response?.status === 200) {
          const reader = new FileReader();
          reader.onloadend = () => {
            // sessionStorage.setItem("apiMessageForm", false)
            // dispatch(showApiMessageForm(sessionStorage.getItem("apiMessageForm")))

            sessionStorage.setItem("qrCodeUrl", reader.result)
            dispatch(setQRCodeUrl(reader.result))

            sessionStorage.setItem("screenshot", true)
            dispatch(showScreenShot(sessionStorage.getItem("screenshot")))
          };
          reader.readAsDataURL(response?.data)
          setQrCodeGenerated(true)
          setGettingScreenshot(false)
          setSessionStatus(true)
        } else if (response?.response?.status === 404) {
          setGettingScreenshot(false)
          setRestartAction(true)
        } else {
          toast.error("unable to get screenshot!", { theme: "colored" })
          console.log(response?.response?.status)
          setGettingScreenshot(false)
        }

      });
  }

  // Handle: Checking if session exists
  const checkObjectExists = (sessions) => {
    return sessions.some(item => item.name === jsonData.company.phoneNumber);
  };


  // Handle: Start new session
  const startWhatsappSession = () => {
    startSession(jsonData.company.phoneNumber).then(resp => {
      if (resp?.status === 200 && resp?.data?.status === "STARTING") {
        setStarting(true)
        setSessionExists(true)
        checkSessionStatus()
      }
    })
  }


  const checkSessionStatus = () => {
    getAllSessions().then(resp => {
      resp.data.filter((item) => item.name === jsonData.company.phoneNumber).forEach((sess) => {
        if (sess.status === "SCAN_QR_CODE") {
          getQRCode()
          console.log("Generating QRCode: " + sess.status)
        } else if (sess.status === "STOPPED") {
          startWhatsappSession()
          console.log("Starting Session status: " + sess.status)
        } else if (sess.status === "WORKING") {
          getScreenshot()
          console.log("Generating Screenshot: " + sess.status)
        }
        else {
          checkSessionStatus()
          setStarting(true)
          console.log("Session status" + sess.status)
          return;
        }
      }
      )
    })
  }


  const stopLogoutSession = () => {
    setLoggingOff(true)
    stopSession(jsonData.company.phoneNumber).then((resp) => {
      console.log("Logging off session....")
      window.location.reload()
      sessionExists(false)
    })
  }

  const handleRestartSession = () => {
    setLoggingOff(true)
    stopSession(jsonData.company.phoneNumber).then((resp) => {
      setRestartAction(false)
      startWhatsappSession()
      setLoggingOff(false)
    })
  }


  return (

    <div className='whatsapp-page'>

      <div className='whatsapp-section'>

        <div>
          {checkingSession ? "Please wait..." : ""}
          {gettingScreenshot ? "Getting whatsapp screenshot..." : ""}
          {!sessionExists ? <button className="connect-sess" onClick={() => startWhatsappSession()}>Generate QRCode</button> : ""}
          {starting ? <p id="session-message">Generating QR Code... This may take a moment</p> : ""}
        </div>


        <div>
          <div id="qrcode-section">
            {qrCodeStatus === "scanQr" ? <p id='qrcode-message'>Once you have successfully linked the device on your WhatsApp, you may proceed to start sending campaigns</p> : ""}
            {qrCodeGenerated ? <img src={whatsAppSelector?.qrCodeUrl} alt="whatsapp-qrcode" /> : ""}
          </div>

          {!sessionStatus ? "" :
            <button className="disconnect-sess" onClick={() => setStopSessionAction(true)}>Disconnect Session</button>}
        </div>


      </div>


      {/* Stop session confirmation modal */}
      <div className={stopSessionAction ? 'upload-error-active' : 'upload-error-inactive'}>
        <div className="upload-error-modal" >
          <img id="delete-img" src="https://100dayscss.com/codepen/alert.png" width="44" height="38" alt='warning' />
          <span className="action-title">Stop Session</span>
          <p className='upload-error-message'>Stopping this session will also force you to manually disconnect your
            connected device in whatsapp</p>

          <div className='a-buttons'>
            {loggingOff ? <button type="button" className="download-error-btn disconnecting">Disconnecting...</button>
              : <button type="button" className="download-error-btn" onClick={() => stopLogoutSession()}>Yes, Stop</button>
            }
            <button type='button' className="dismiss-error-button" onClick={() => setStopSessionAction(false)}>Dismiss</button>
          </div>

        </div>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />


      <div className={restartAction ? 'delete-modal-active' : 'delete-modal-inactive'}>
        <div className="delete-modal" >
          <img id="delete-img" src="https://100dayscss.com/codepen/alert.png" width="44" height="38" alt='warning' />
          <span className="action-title">Session Ended!</span>
          <p className='ended-session-message'>Session closed. Most likely the page has been closed.
          Restart Session to continue</p>

          <div className='a-buttons'>
            <button type='button' className="dismiss-button" onClick={() => handleRestartSession()}>
              {loggingOff? 'Restarting Session...' : 'Restart Session'}</button>
          </div>
        </div>
      </div>

    </div>

  )

}

export default WhatsaapMessages;